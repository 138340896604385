import React, { useState } from "react"
import "./style.scss"
import StarsIcon from "@mui/icons-material/Stars"

const UniprocessSegmentsPage = () => {
  return (
    <div className="uniprocess-segments-page">
      <h1 className="segments-title">Segmentos</h1>
      <p className="segments-subtitle">Onde atuamos</p>
      <div className="about-framework">
        <div className="segments-items">
          <>
            <div className="segment-item">
              <div className="segment-icon">
                <StarsIcon fontSize="large" />
              </div>
              <p>
                <h3 className="segment-title">Serviços Financeiros</h3>⦁{" "}
                <b>
                  Retorno rápido para orquestrações de fluxo de processo
                  financeiro
                </b>{" "}
                e de backoffice para Centro de Serviços Compartilhados.
              </p>
            </div>

            <div className="segment-item">
              <div className="segment-icon">
                <StarsIcon fontSize="large" />
              </div>
              <p>
                <h3 className="segment-title">Auditoria</h3>⦁{" "}
                <b>Combate a fraudes e tentativas de mal uso de benefícios.</b>{" "}
                Possuímos cases de sucesso com redução de lead time para os
                processos de auditoria e redução do número de tentativas e
                descoberta de fraudes.
              </p>
            </div>

            <div className="segment-item">
              <div className="segment-icon">
                <StarsIcon fontSize="large" />
              </div>
              <p>
                <h3 className="segment-title">Seguros</h3>⦁{" "}
                <b>Orquestração do crescimento sustentável.</b> Com aceleração
                da transformação digital escalável, as seguradores devem agir
                rapidamente para permanecer competitivas e eficientes.
              </p>
            </div>

            <div className="segment-item">
              <div className="segment-icon">
                <StarsIcon fontSize="large" />
              </div>
              <p>
                <h3 className="segment-title">Indústria</h3>⦁{" "}
                <b>Aumentando sua produtividade.</b> Tentando equilibrar as
                pressões orçamentárias e as demandas crescentes, indústrias de
                todo o mundo estão procurando maneiras novas e inovadoras de
                gerenciar e melhorar seus processos e produtos.
              </p>
            </div>
          </>
        </div>
        <div className="segments-items">
          <>
            <div className="segment-item">
              <div className="segment-icon">
                <StarsIcon fontSize="large" />
              </div>
              <p>
                <h3 className="segment-title">Saúde</h3>⦁{" "}
                <b>Garantia de assistência para quem realmente necessita.</b>{" "}
                Empresas do setor de saúde estão perdendo espaço para startups
                que possuem seus processos originalmente digitais. Podemos
                ajudar na aceleração da digitalização e orquestração de
                processos core gerando mais eficiência entre todas as áreas de
                negócio.
              </p>
            </div>

            <div className="segment-item">
              <div className="segment-icon">
                <StarsIcon fontSize="large" />
              </div>
              <p>
                <h3 className="segment-title">Tecnologia</h3>⦁{" "}
                <b>Integrando todos seus sistemas.</b> Para as organizações que
                operam no espaço da tecnologia, isso significa que elas não
                apenas precisam acompanhar as iniciativas de transformação
                digital, mas também precisam dar um passo adiante
                com hiperautomação, inteligência artificial, IoT e computação em
                nuvem.
              </p>
            </div>

            <div className="segment-item">
              <div className="segment-icon">
                <StarsIcon fontSize="large" />
              </div>
              <p>
                <h3 className="segment-title">Varejo</h3>⦁{" "}
                <b>Acompanhamos mudanças rápidas.</b> Operando em um mercado
                altamente competitivo que é definido por mudanças constantes,
                empresas do varejo podem se beneficiar com processos mais ágeis
                e escaláveis.
              </p>
            </div>
          </>
        </div>
      </div>
    </div>
  )
}

export default UniprocessSegmentsPage
